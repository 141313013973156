<template>
  <auth-card wrapper-classes="w-500"
    btn-classes="full-width"
    :show-close-btn="false">
    <template #description>
      <h1>{{ $t('users.mfa-long') }}</h1>

      <el-alert class="mb-0 mt-3"
        :closable="false"
        :title="alertTitle" />

      <mfa-qr v-model="secret" />

      <el-form ref="mfaForm"
        :model="mfaForm"
        :rules="mfaFormRules"
        @submit.native.prevent="enableMfa">
        <el-form-item :label="$t('users.mfa-token')"
          prop="oneTimePassword">
          <el-input v-model.trim="mfaForm.oneTimePassword"
            :placeholder="$t('general.token')"
            data-testid="setup-mfa-otp-input"
            name="oneTimePassword"
            autocomplete="off" />
        </el-form-item>
      </el-form>

      <el-button class="full-width"
        type="primary"
        data-testid="setup-mfa-enable-button"
        @click="enableMfa">
        {{ $t('users.enable-mfa') }}
      </el-button>

      <el-button class="full-width mt-1"
        type="text"
        data-testid="setup-mfa-cancel-button"
        @click="cancel">
        {{ $t('general.cancel') }}
      </el-button>
    </template>
  </auth-card>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import sdk from '@megaport/api-sdk'

import AuthCard from '@/components/ui-components/AuthCard.vue'
import MfaQr from './MfaQr.vue'

export default {
  name: 'SetupMfa',

  components: {
    'auth-card': AuthCard,
    'mfa-qr': MfaQr,
  },

  inject: ['disabledFeatures', 'isFeatureEnabled'],

  data() {
    return {
      secret: '',
      mfaForm: {
        oneTimePassword: null,
      },
      mfaFormRules: {
        oneTimePassword: { required: true, message: this.$t('validations.required', { thing: this.$t('general.token') }), trigger: 'blur' },
      },
    }
  },

  computed: {
    ...mapState('Auth', { auth: state => state.data }),
    ...mapGetters('Auth', ['requiresMfaSetup', 'isDistributorAccount', 'homePage']),

    alertTitle() {
      return this.auth.mfaReset ? this.$t('authentication.setup-mfa-reset') : this.$t('authentication.setup-mfa-enforced')
    },
  },

  mounted() {
    if (!this.requiresMfaSetup) this.$router.push(this.homePage)
  },

  methods: {
    ...mapActions('Auth', ['logout']),
    ...mapActions('Services', ['getMyServices']),
    ...mapMutations('Auth', ['setMfaEnabled', 'setMfaReset', 'setLoggingIn']),
    ...mapMutations('Notifications', ['notifyGood', 'notifyBad']),

    async cancel() {
      try {
        const confirmOpts = {
          cancelButtonText: this.$t('general.close'),
          confirmButtonText: this.$t('general.confirm'),
        }

        await this.$confirm(this.$t('authentication.setup-mfa-cancel-confirm'), confirmOpts)
        this.logout()
      } catch {
        /* Do nothing */
      }
    },

    async enableMfa() {
      const loader = this.$loading({
        lock: true,
        text: this.$t('authentication.enabling-mfa'),
        background: 'rgba(255, 255, 255, 0.95)',
      })

      try {
        const employeeId = this.auth.personId
        await sdk.instance
          .employee(employeeId)
          .enableMfa({
            totpSecret: this.secret,
            totpCode: this.mfaForm.oneTimePassword,
          })

        this.setMfaEnabled()
        this.setMfaReset()

        this.notifyGood({ title: this.$t('users.enable-mfa-success') })

        loader.setText(this.$t('services.loading-services'))
        await this.getMyServices()

        // This doesn't run on login when MFA is required to be setup, so make sure we trigger it before login.
        await this.$store.dispatch('onLogin', null, { root: true })

        this.$router.push(this.homePage)
        loader.close()
      } catch (error) {
        this.notifyBad({
          title: `${this.$t('authentication.enable-mfa-failed')}: ${error.data?.message || this.$t('general.unknown-error')}`,
        })
      } finally {
        this.setLoggingIn(false)
        loader.close()
      }

      return
    },
  },
}
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
  ::v-deep .w-500 {
    width: 500px;
  }
}

/* some styling interfering with this elsewhere, so override it locally */
.el-button + .el-button {
  margin-left: 0;
}
</style>
