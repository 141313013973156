<template>
  <section>
    <div v-loading="loading"
      class="text-align-center"
      :element-loading-text="$t('users.loading-qr-code')">
      <img v-if="qrCode"
        :src="qrCode"
        :alt="$t('users.mfa-alt-tag')">
      <div v-else
        class="image-placeholder" />
    </div>
    <p>
      {{ splitInstructionsString[0] }}
      <el-tooltip placement="top"
        :content="secret + ' ' + $t('general.click-copy')"
        :open-delay="500">
        <el-button type="text"
          @click="copyToClipboard(secret)">
          {{ splitInstructionsString[1] }}
          <i class="far fa-clipboard"
            aria-hidden="true" />
        </el-button>
      </el-tooltip>
      {{ splitInstructionsString[2] }}
    </p>
    <p>{{ $t('users.mfa-app-suggestion') }}</p>
  </section>
</template>

<script>
import { mapMutations, mapGetters, mapState } from 'vuex'
import sdk from '@megaport/api-sdk'
import { copyToClipboard } from '@/helpers.js'

export default {
  name: 'MfaQr',

  data() {
    return {
      qrCode: '',
      loading: false,
      secret: this.$t('general.loading'),
    }
  },

  computed: {
    ...mapState('Auth', ['loggedIn']),
    ...mapGetters('Auth', ['hasFeatureFlag']),

    // This is an attempt to translate a string in such a way as to be able to split it into parts.
    // So long as the translation comes back with a string split into 3 sections with a | pipe between
    // the sections and are logically the same order as the original string, it should work fine. May
    // have to be careful in RTL languages.
    splitInstructionsString() {
      const translated = this.$t('authentication.mfa-instructions')
      const split = translated.split('|')
      if (split.length !== 3) {
        console.error(`Error splitting translated string ${translated} into three parts`)
        return null
      }
      return split
    },
  },

  created() {
    if (this.loggedIn) this.loadQrCode()
  },

  methods: {
    ...mapMutations('Notifications', ['notifyBad']),

    copyToClipboard,

    async loadQrCode() {
      try {
        this.loading = true
        this.secret = this.$t('general.loading')

        const data = await sdk.instance.profile().mfaQr()

        this.secret = data.secret
        this.qrCode = data.qrcode

        this.$emit('input', this.secret)
      } catch (error) {
        this.secret = this.$t('users.mfa-loading-failed')
        this.notifyBad({
          title: this.$t('general.load-failed'),
          message: `${this.$t('users.mfa-loading-failed')} ${error.data?.message || this.$t('general.unknown-error')}`,
        })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
<style scoped>
.image-placeholder {
  width: 350px;
  height: 350px;
}
</style>
